.App {
  text-align: center;
}

body,
html {
  height: 100%;
  width: 100%;
  background-color: #add0e2;
}

body {
  overflow-x: hidden;
}

.vertical-timeline-element-date {
  font-weight: bolder !important;
  align-items: center !important;
}

body::-webkit-scrollbar {
  width: 0.6rem;
}

body::-webkit-scrollbar-track {
  background: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background: #6c88e1;
}
